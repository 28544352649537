@font-face {
  font-family: "Europa";
  src: local("Europa"),
    url(./fonts/europa-regular-webfont.ttf) format("truetype");
}
@font-face {
  font-family: "EuropaLight";
  src: local("EuropaLight"),
    url(./fonts/europa-light-webfont.ttf) format("truetype");
}
@font-face {
  font-family: "EuropaBold";
  src: local("EuropaBold"),
    url(./fonts/europa-bold-webfont.ttf) format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Europa", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
